<script lang="ts">
  import defaultAvatar1Img from "./default-avatar-1.svg";
  import defaultAvatar2Img from "./default-avatar-2.svg";
  import defaultAvatar3Img from "./default-avatar-3.svg";
  import defaultAvatar4Img from "./default-avatar-4.svg";
  import defaultAvatar5Img from "./default-avatar-5.svg";
  import defaultAvatar6Img from "./default-avatar-6.svg";

  export let avatarUrl: string = "";
  export let size: number;

  export let name: string;

  $: letter = name[0] ?? "?";
  let fontSize = size / 1.75; // scale font so it doesn't look weird with img size

  const defaultImageByName = (n: string) => {
    const images = [
      defaultAvatar1Img,
      defaultAvatar2Img,
      defaultAvatar3Img,
      defaultAvatar4Img,
      defaultAvatar5Img,
      defaultAvatar6Img,
    ];
    return images[n.charCodeAt(0) % 6];
  };
</script>

{#if avatarUrl}
  <img
    class="inline-flex avatar-icon"
    style="width: {size}px; height: {size}px;"
    src={avatarUrl}
    alt="User avatar"
  />
{:else}
  <div
    class="inline-flex justify-center items-center relative avatar-icon overflow-clip select-none"
    style="width: {size}px; height: {size}px;"
  >
    <img
      class="absolute -z-10 inset-0 opacity-70"
      src={defaultImageByName(letter)}
      alt="User avatar"
    />
    <span
      class="text-white/75 font-tosh pl-[1px] pt-[1px] font-medium uppercase"
      style="font-size: {fontSize}px;">{letter}</span
    >
  </div>
{/if}

<style lang="postcss">
  .avatar-icon {
    @apply rounded-full border border-white/10 object-cover;
  }
</style>
